<template>
    <div class="d-flex flex-column fill-height">
        <v-col>
            <v-row no-gutters>
                <v-col cols="12" class="font-weight-bold document-caption d-flex flex-row justify-start">{{ Office__title }}<span style="font-size: 60%" class="ml-2 mt-0 pt-0">({{ Office__companyno }})</span></v-col>
            </v-row>
            <v-row no-gutters class="pt-4">
                <v-col cols="12" class="text-center text-uppercase border-top-2 border-bottom-2 pa-0 font-weight-bold document-caption">{{ $t('message.letterOfCreditAdvice') }}</v-col>
            </v-row>
            <v-row no-gutters class="pt-1">
                <v-col cols="6" class="font-weight-bold">{{ $t('message.contractNo') + " : " + (Contract__version != null ? [Contract__title, Contract__revision_no , Contract__partial_no].filter(Boolean).join('-') : Contract__title) }}</v-col>
                <v-col cols="6" class="font-weight-bold">{{ $t('message.date') + " : " + formatDate(Lca__lcadate) }}</v-col>
            </v-row>
            <v-row no-gutters class="pt-4">
                <v-col cols="6" class="d-flex flex-column">
                    <div class="font-weight-bold">{{ $t('message.beneficiary') + " : " }}</div>
                    <template v-if="Contract__salestype_id != 2">
                        <span>{{ Office__title }}</span>
                        <span>{{ Office__address1 }}</span>
                        <span>{{ Office__address2 }}</span>
                        <span>{{ Office__address3 }}</span>
                        <span>{{ Office__city + ' ' + Office__postcode}}</span>
                        <span>{{ Office__state }}</span>
                        <span>{{ Office__country }}</span>
                    </template>
                    <template v-else>
                        <span>{{ Supplier__title }}</span>
                        <span>{{ Supplier__address1 }}</span>
                        <span>{{ Supplier__address2 }}</span>
                        <span>{{ Supplier__address3 }}</span>
                        <span>{{ Supplier__city + ' ' + Supplier__postcode}}</span>
                        <span>{{ Supplier__state }}</span>
                        <span>{{ Supplier__country }}</span>
                    </template>
                </v-col>
            </v-row>
            <v-row no-gutters class="pt-4">
                <v-col cols="12" class="font-weight-bold" v-html="lcaConstants.LcaNote1.replace('dd-MM-YYYY',formatDate(Lca__lcalatestdate))"></v-col>
            </v-row>
            <v-row no-gutters class="pt-4">
                <v-col class="font-weight-bold col-2-5">{{ $t('message.bank') }}<span class="float-right mr-5">:</span></v-col>
                <v-col class="col-9-5">{{ Bank__title }}</v-col>
            </v-row>
            <v-row no-gutters>
                <v-col class="font-weight-bold col-2-5">{{ $t('message.address') }}<span class="float-right mr-5">:</span></v-col>
                <v-col cols="4">{{ Bank__address }}</v-col>
            </v-row>
            <v-row no-gutters>
                <v-col class="font-weight-bold col-2-5">{{ $t('message.account') }}<span class="float-right mr-5">:</span></v-col>
                <v-col class="col-9-5">{{ Bank__account }}</v-col>
            </v-row>
            <v-row no-gutters>
                <v-col class="font-weight-bold col-2-5">{{ $t('message.swiftCode') }}<span class="float-right mr-5">:</span></v-col>
                <v-col class="col-9-5">{{ Bank__swiftcode }}</v-col>
            </v-row>
            <v-row no-gutters class="pt-4">
                <v-col class="font-weight-bold col-2-5">{{ $t('message.amount') }}<span class="float-right mr-5">:</span></v-col>
                <v-col class="col-9-5">{{ currencies.find(currency => currency.Currency.id == Contract__currency_id).Currency.code + " " + formatThisNumber(Contract__value, currencyFormatPrecision) }}</v-col>
            </v-row>
            <v-row no-gutters class="pt-4">
                <v-col class="font-weight-bold col-2-5">{{ $t('message.description') }}<span class="float-right mr-5">:</span></v-col>
                <v-col class="col-9-5">
                    <div v-for="(description, index) in itemDescriptions" :key="index">
                        {{ description.Salesdescription.lcdescription != null && description.Salesdescription.lcdescription != '' ? description.Salesdescription.lcdescription : description.Salesdescription.title }}
                    </div>
                </v-col>
            </v-row>
            <v-row no-gutters class="pt-4">
                <v-col cols="5"><span class="font-weight-bold">{{ $t('message.latestShipmentDate') + ' :  ' }}</span>{{ formatDate(Lca__latestshipdate) }}</v-col>
                <v-col cols="7"><span class="font-weight-bold">{{ $t('message.expiryDate') + ' :  ' }}</span>{{ formatDate(Lca__expirydate) }}</v-col>
            </v-row>
            <v-row no-gutters class="pt-4">
                <v-col cols="5"><span class="font-weight-bold">{{ $t('message.partialShipment') + ' :  ' }}</span>{{ Lca__partialshipment }}</v-col>
                <v-col cols="7"><span class="font-weight-bold">{{ $t('message.transhipment') + ' :  ' }}</span>{{ Lca__transhipment }}</v-col>
            </v-row>
            <v-row no-gutters class="pt-4">
                <v-col cols="5"><span class="font-weight-bold">{{ $t('message.portOfLoading') + ' :  ' }}</span>{{ Loadingport__title }}</v-col>
                <v-col cols="7"><span class="font-weight-bold">{{ $t('message.finalDestination') + ' :  ' }}</span>{{ Shippingport__title }}</v-col>
            </v-row>
            <v-row no-gutters class="pt-4">
                <v-col cols="6"><span class="font-weight-bold">{{ $t('message.incotermOfLc') + ' :  ' }}</span>{{ incoterms.find(incoterm => incoterm.Incoterm.id == Contract__incoterm_id).Incoterm.title }}</v-col>
            </v-row>
            <v-row no-gutters class="pt-4">
                <v-col cols="6"><span class="font-weight-bold">{{ $t('message.lcTerm') + ' :  '}}</span>{{ Lca__negotiable }}</v-col>
            </v-row>
            <v-row no-gutters class="pt-4">
                <v-col cols="12"><span class="font-weight-bold">{{ $t('message.documentsRequired') }}</span></v-col>
                <v-col cols="12">
                    <ul>
                        <li v-for="clause in Clauses" v-bind:key="clause.Clause.id">{{ clause.Clause.title }}</li>
                    </ul>
                </v-col>
            </v-row>
            <v-row no-gutters class="pt-3">
                <v-col cols="12"><span class="font-weight-bold">{{ $t('message.additionalConditions') }}</span></v-col>
                <v-col cols="12">
                    <ul>
                        <li v-for="condition in lcaConstants.LcaConditions" v-bind:key="condition.id">{{ condition.title }}</li>
                    </ul>
                </v-col>
            </v-row>
            <v-row no-gutters class="pt-5">
                <v-col cols="12" class="font-weight-bold">{{ lcaConstants.LcaNote2 }}</v-col>
            </v-row>
            <v-row no-gutters class="pt-5">
                <v-col cols="12" class="font-weight-bold">{{ lcaConstants.LcaNote3 }}</v-col>
            </v-row>
        </v-col>
        <div class="px-0 pb-0 ma-0 pt-3 mt-auto">
            <v-row no-gutters>
                <v-col cols="12" class="text-center font-weight-bold border-top-2 border-bottom-2">{{ lcaConstants.LcaNote4 }}</v-col>
            </v-row>
        </div>
        <h1 id="loaded" v-if="loaded" style="margin-bottom: 0px !important;"></h1>
    </div>
</template>

<script>
import {formatDate, log, numberFormat} from "../../../helpers/helpers";
    import { api } from "../../../api";
    import {
        // currencies,
        incoterms,
        lcaConstants
    } from "../../../store/modules/appic/constants"

	export default {
		name: "PrintLca",
        props: ['lcaId'],
        data() {
            return {
                currencies: [],
                // currencies: currencies,
                incoterms: incoterms,
                itemDescriptions: [],
                lcaConstants: lcaConstants,
                loaded: false,
                Bank__address: null,
                Bank__account: null,
                Bank__swiftcode: null,
                Bank__title: null,
                Clauses: [],
                Contract__currency_id: 1,
                Contract__incoterm_id: 3,
                Contract__partial_no: null,
                Contract__revision_no: null,
                Contract__salestype_id: null,
                Contract__title: null,
                Contract__version: null,
                Contract__value: null,
                Lca__contract_id: null,
                Lca__description: null,
                Lca__expirydate: null,
                Lca__latestshipdate: null,
                Lca__lcadate: null,
                Lca__lcalatestdate: null,
                Lca__negotiable: null,
                Lca__partialshipment: null,
                Lca__title: null,
                Lca__transhipment: null,
                Loadingport__title: null,
                Office__title: null,
                Office__address1: null,
                Office__address2: null,
                Office__address3: null,
                Office__city: null,
                Office__companyno: null,
                Office__postcode: null,
                Office__state: null,
                Office__country: null,
                Shippingport__title: null,
                Supplier__address1: null,
                Supplier__address2: null,
                Supplier__address3: null,
                Supplier__city: null,
                Supplier__postcode: null,
                Supplier__state: null,
                Supplier__country: null
            }
        },
        computed: {
            currencyFormatPrecision () {
                return this.currencies.find((currency) => currency.Currency.id === this.Contract__currency_id)?.Currency?.formatPrecision
            },
        },
        methods: {
            formatDate,
            formatThisNumber(value,format){
                return numberFormat(value,format)
            },
            getContractValues (contract_id) {
                return new Promise((resolve, reject) => {
                    api.get('/print/contracts/'+ contract_id + '/values')
                        .then(response => resolve(response.data.values))
                        .catch(error => reject(error))
                })
            },
            getCurrencies() {
                return new Promise((resolve, reject) => {
                    log('get currencies')
                    api
                        .get("/print/currencies",{
                        })
                        .then(response => {
                            resolve(response.data.data)
                        })
                        .catch(error => {
                            reject(error);
                        });
                })
            },
            getItemDescriptionsByContract ( contract_id ) {
                return new Promise((resolve, reject) => {
                    api
                        .get("/print/contracts/" + contract_id + "/salesdescriptions")
                        .then(response => {
                            //ensure no double entries
                            let descriptions = []
                            let descriptionTitles = []
                            response.data.data.forEach(description => {
                                if(descriptionTitles.includes(description.Salesdescription.title) == false){
                                    descriptionTitles.push(description.Salesdescription.title)
                                    descriptions.push(description)
                                }
                            })
                            resolve(descriptions)
                        })
                        .catch(error => {
                            reject(error);
                        })
                        .finally(()=>{
                            resolve('done')
                        })
                })
            },
            loadLcaById (lca_id) {
                let self = this
                return new Promise((resolve, reject) => {
                    api
                        .get("/print/lcas/" + lca_id,{
                        })
                        .then(response => {
                            for(let key in response.data.data[0]){
                                self[key] = response.data.data[0][key]
                            }
                            resolve(lca_id)
                        })
                        .catch(error => {
                            reject(error);
                        })
                        .finally(()=>{})
                })
            },
            loadClausesByLca (lca_id) {
                let self = this
                return new Promise((resolve, reject) => {
                    api
                        .get("/print/lcas/" + lca_id + "/clauses",{
                        })
                        .then(response => {
                            self.Clauses = response.data.data
                            resolve(lca_id)
                        })
                        .catch(error => {
                            reject(error);
                        })
                })
            }
        },
        created () {
            this.getCurrencies()
                .then((currencies) => {
                    this.currencies = currencies
                })
                .catch()
            if(this.$route.matched.some(({name}) => name === 'print_lca')){
                this.loadLcaById(this.lcaId)
                    .then(() => {
                        this.getContractValues(this.Lca__contract_id)
                            .then((values) => {
                                this.Contract__value = values.contract
                                this.loadClausesByLca(this.lcaId)
                                    .then(()=>{
                                        this.getItemDescriptionsByContract(this.Lca__contract_id)
                                            .then((response)=>{
                                                this.itemDescriptions = response
                                                this.loaded = true
                                            })
                                    })
                            })
                    })
            }
        }
	}
</script>

<style>
    @font-face {
        font-family: 'AppicArial';
        src: url('/static/fonts/Arial.woff2') format('woff2'),
        url('/static/fonts/Arial.woff2') format('woff'),
        url('/static/fonts/Arial.woff2') format('truetype');
    }
    .col-2-5 {
        flex: 0 0 20.83333333%;
        max-width: 20.83333333%;
    }
    .col-3-5 {
        flex: 0 0 29.16666667%;
        max-width: 29.16666667%;
    }
    .col-4-5 {
        flex: 0 0 37.5%;
        max-width: 37.5%;
    }
    .col-9-5 {
        flex: 0 0 79.16666667%;
        max-width: 79.16666667%;
    }
    .border-top-1 {
        border-top: 1px solid black !important;
    }
    .border-bottom-1 {
        border-bottom: 1px solid black !important;
    }
    .border-top-2 {
        border-top: 2px solid black;
    }
    .border-bottom-2 {
        border-bottom: 2px solid black !important;
    }
    .document-caption {
        font-size: 16px !important;
        line-height: 18px;
    }
    .footer-note {
        font-size: 8pt !important;
    }
    .header-title {
        font-size: 20pt !important;
        line-height: 25px;
        font-weight: bold !important;
    }
    .header-subtitle {
        font-size: 10.5pt !important;
    }
    .v-application{
        font-family: 'AppicArial' !important;
        line-height:15px !important;
        font-size:9pt !important;
        padding:2px !important;
    }
    body {
        width: 18.5cm !important;
        margin: 0 !important;
    }
</style>